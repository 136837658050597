import { nanoid } from 'nanoid';

// HEAD DATA
export const headData = {
  title: 'The Shahin Group', // e.g: 'Name | Developer'
  lang: 'en', // e.g: en, es, fr, jp
  description: 'The Shahin Group: Cloud, Development, and DevOps consultation.', // e.g: Welcome to my website
};

// HERO DATA
export const heroData = {
  title: 'The',
  name: 'Shahin Group',
  subtitle: 'Building Resilient, Scalable Solutions',
  cta: '',
};

// ABOUT DATA
export const aboutData = {
  img: 'about-us.jpg',
  paragraphOne:
    'The Shahin Group is the perfect partner to help take your business to the next level.',
  paragraphTwo:
    "We offer a variety of solutions and services to help you build and scale your business on the web. Whether you need a simple site for your small business or a robust and complex web application, we've got you covered.",
  paragraphThree:
    'We work with clients to fit their budget while delivering results in an efficient and timely manner.',
  resume: '', // if no resume, the button will not show up
};

// PROJECTS DATA
export const projectsData = [
  {
    id: nanoid(),
    //img: 'cloud-dev.jpg',
    img: 'cloud.png',
    title: 'Cloud Solutions',
    info:
      'Not only can we help migrate your services to the cloud, but we can help design and architect resilient, highly available solutions for your business.',
    info2: '',
    //url: '',
    //repo: '', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'devops.png',
    title: 'DevOps Accelerator',
    info:
      'We can help you and your team establish DevOps practices that will enable them to continuously build, ship, and monitor your code safely and efficiently.',
    info2: '',
    //url: '',
    //repo: '', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    //img: 'app-development.jpg',
    img: 'app-dev.png',
    title: 'Application Development',
    info:
      "We'll help you develop robust, scalable, and secure applications to meet your business needs. From responsive user interfaces to complex backend services.",
    info2: '',
    //url: '',
    //repo: '', // if no repo, the button will not show up
  },
];

// CONTACT DATA
export const contactData = {
  cta: 'Need help with your web solutions?',
  btn: '',
  email: 'theshahingroup@gmail.com',
};

// FOOTER DATA
export const footerData = {
  networks: [
    {
      id: nanoid(),
      name: 'linkedin',
      url: 'https://www.linkedin.com/in/kareem-shahin',
    },
    {
      id: nanoid(),
      name: 'github',
      url: 'https://github.com/kareemshahin',
    },
  ],
};

// Github start/fork buttons
export const githubButtons = {
  isEnabled: false, // set to false to disable the GitHub stars/fork buttons
};
